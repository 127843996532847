/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { styled } from '@superset-ui/core';
import cls from 'classnames';
import Lottie from 'react-lottie-player';
import loadingAnimation from 'viana-lottie.json';

export type PositionOption =
  | 'floating'
  | 'inline'
  | 'inline-centered'
  | 'normal';

export interface Props {
  position?: PositionOption;
  className?: string;
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  &.floating {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.inline {
    display: inline-flex;
  }

  &.inline-centered {
    justify-content: center;
  }
`;

const LottieWrapper = styled.div`
  width: 100px;
  height: 100px;
`;

export default function Loading({ position = 'floating', className }: Props) {
  return (
    <LoaderContainer className={cls(position, className)}>
      <LottieWrapper>
        <Lottie
          role="status"
          aria-live="polite"
          aria-label="Loading"
          loop
          animationData={loadingAnimation}
          speed={0.75}
          play
          style={{ width: '60%', height: '60%' }}
        />
      </LottieWrapper>
    </LoaderContainer>
  );
}